import React, { FC, useState } from "react";
import "../styles/global.css";

interface HeaderProps {
  siteTitle: string;
}

const HeaderSet: FC<HeaderProps> = ({ siteTitle }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleNavbar = () => setIsExpanded(!isExpanded);

  return (
    <header
      style={{
        backgroundColor: "#faf4f2",
      }}
    >
      <nav id="header" className="w-full z-30 top-0 py-2  shadow-lg p-10">
        <div className="w-full flex flex-wrap items-center justify-between mt-0 md:px-6 py-2">
          <button
            className="cursor-pointer md:hidden block"
            onClick={toggleNavbar}
          >
            <svg
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>

          <div
            className={`md:flex md:items-center md:w-auto w-full order-3 md:order-1 ${
              isExpanded ? `block` : `hidden`
            } md:block`}
            id="menu"
          >
            <nav>
              <ul className="md:flex items-center justify-between text-base text-gray-600 pt-4 md:pt-0 font-semibold">
                <li>
                  <a
                    className="inline-block no-underline hover:text-gray-500 text-lg py-2 px-0 md:px-4 lg:-ml-2"
                    href="/"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    className="inline-block no-underline hover:text-gray-500 text-lg py-2 px-0 md:px-4 lg:-ml-2"
                    href="/about"
                  >
                    Acerca de
                  </a>
                </li>
                <li>
                  <a
                    className="inline-block no-underline hover:text-gray-500 text-lg py-2 px-0 md:px-4 lg:-ml-2"
                    href="/charity"
                  >
                    Donar
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div
            className="order-2 md:order-3 flex flex-wrap items-center justify-end mr-0 md:mr-4"
            id="nav-content"
          >
            <div className="auth flex items-center w-full md:w-full">
              <a href="/" title="Home">
                <img className="w-12" src="/images/logo.jpg" />
              </a>
              {/* <button className="bg-transparent text-gray-800  p-2 rounded border border-gray-300 mr-4 hover:bg-gray-100 hover:text-gray-700">Sign in</button>
               <button className="bg-red-600 text-gray-200  p-2 rounded  hover:bg-red-500 hover:text-gray-100">Sign up</button> */}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderSet;
