import React, { FC } from "react";
import '../styles/global.css'

interface HeaderProps {
  siteTitle: string;
}

const FooterMain: FC<HeaderProps> = ({ siteTitle }) => (
  <footer className="bg-blue-600 text-white mt-20">
    <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
        <div>
        <img src="#" className="mr-5 h-6 sm:h-9" alt="El Amor Te Elige A Ti" />
          <p className="max-w-xs mt-4 text-sm ">
          “EL SECRETO DE LA EXISTENCIA NO CONSISTE EN VIVIR, SINO EN SABER PARA QUÉ SE VIVE”
          </p>
          <div className="flex mt-8 space-x-6 ">
            <a className="hover:opacity-75" target="_blank" rel="noreferrer" href="https://www.facebook.com/ElAmorTeElijeATi">
              <span className="sr-only"> Facebook </span>
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
              </svg>
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
          <div>
            <p className="font-medium">
              Acerca de
            </p>
            <nav className="flex flex-col mt-4 space-y-2 text-sm">
              <a className="hover:opacity-75" href="/charity"> Página de donación </a>
            </nav>
          </div>
          <div>
            <p className="font-medium">
              Legal
            </p>
            <nav className="flex flex-col mt-4 space-y-2 text-sm">
              <a className="hover:opacity-75" href="/privacy-policy"> Política de privacidad </a>
              <a className="hover:opacity-75" href="/terms-conditions"> Términos &amp; condiciones </a>
            </nav>
          </div>
        </div>
      </div>
      <p className="mt-8 text-xs text-white">
        © 2022 Hecho por <a href="https://foxlabsdevelopers.com">Foxlabs Developers</a>
      </p>
    </div>
  </footer>
);

export default FooterMain;