import React, { FC } from "react";
import Header from "./header";
import Footer from "./footer";

interface LayoutProps {
  children: React.ReactNode
}


const Layout: React.FunctionComponent<LayoutProps> = (props:LayoutProps) => {
  return <div>
    <Header siteTitle="El Amor Te Elige A Ti"></Header>
      {props.children}
    <Footer siteTitle="This is the footer"></Footer>
  </div>
}

export default Layout;